<template>
  <form-wizard
    shape="circle"
    color="#3949ab"
    :title="null"
    :subtitle="null"
    back-button-text="Voltar"
    next-button-text="Próximo"
    finish-button-text="Finalizar"
    class="mb-3"
    @on-complete="salvarAntecipacao"
  >
    <!-- Segurado -->
    <tab-content title="Segurado" :before-change="validationDadosSegurado">
      <validation-observer ref="tomRules" tag="form">
        <b-form-group v-show="campoSeguradoSelecionado" label="Segurado" label-for="segurado">
          <validation-provider #default="{ errors }" name="Segurado" rules="required">
            <vue-autosuggest
              id="segurado"
              v-model="antecipacao.tomador.nomeCompleto"
              :suggestions="suggestions"
              :input-props="{ id: 'autosuggest__input', class: 'form-control', placeholder: 'Digite aqui o nome do tomador' }"
              :get-suggestion-value="getSuggestionValue"
              @selected="getSuggestionValue"
              @input="fetchResults"
            >
              <template slot-scope="{ suggestion }">
                <div class="d-flex align-items-center">
                  <b-avatar :src="suggestion.item.segurado.tomador.foto ? suggestion.item.segurado.tomador.foto.foto : null" />
                  <div class="detail ml-50">
                    <b-card-text class="mb-0">
                      {{ suggestion.item.segurado.tomador.nomeCompleto }}
                    </b-card-text>
                    <small class="text-muted">
                      CPF: {{ suggestion.item.segurado.tomador.cpf }} - Parcelas: {{ suggestion.item.numeroParcelas }} -
                      <b-badge pill variant="primary"> Contrato: {{ suggestion.item.codigo }} </b-badge>
                      <b-badge v-if="suggestion.item.segurado.tomador.falecido == true" class="ml-1" pill variant="danger">{{
                        'Falecido'
                      }}</b-badge>
                      <b-badge v-if="suggestion.item.segurado.exonerado == true" class="ml-1" pill variant="danger">{{
                        'Exonerado'
                      }}</b-badge>
                    </small>
                  </div>
                </div>
              </template>
            </vue-autosuggest>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Campos do tomador -->
        <b-row v-show="this.exibeDadosSegurado">
          <b-col md="3">
            <b-form-group label="CPF" label-for="cpf">
              <b-form-input id="cpf" v-model="antecipacao.tomador.cpf" readonly type="text" placeholder="CPF" />
            </b-form-group>
          </b-col>
          <b-col md="9">
            <b-form-group label="Nome Completo" label-for="nomeCompleto">
              <b-form-input
                id="nomeCompleto"
                v-model="antecipacao.tomador.nomeCompleto"
                readonly
                type="text"
                placeholder="Nome Completo"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-show="this.exibeDadosSegurado">
          <b-col md="3">
            <b-form-group label="Categoria" label-for="categoria">
              <b-form-input
                id="categoria"
                :value="antecipacao.segurado.situacaoPrevidenciaria"
                readonly
                type="text"
                placeholder="Categoria"
              />
            </b-form-group>
          </b-col>
          <b-col md="9">
            <b-form-group label="Nome Social" label-for="nomeSocial">
              <b-form-input
                id="nomeSocial"
                v-model="antecipacao.tomador.nomeSocial"
                readonly
                type="text"
                placeholder="Nome Social"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-show="this.exibeDadosSegurado">
          <b-col md="3">
            <b-form-group label="Data de Nascimento" label-for="dataNascimento">
              <b-form-input
                id="dataNascimento"
                v-model="antecipacao.tomador.dataNascimento"
                readonly
                type="text"
                placeholder="Data de Nascimento"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Remuneração" label-for="remuneracaoProventos">
              <b-form-input
                id="remuneracaoProventos"
                v-model="antecipacao.segurado.remuneracao"
                readonly
                type="text"
                placeholder="Remuneração"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Contrato" label-for="contrato">
              <b-form-input
                id="contrato"
                v-model="antecipacao.contratoSelecionado.codigo"
                readonly
                type="text"
                placeholder="Contrato"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Parcelas" label-for="parcelas">
              <b-form-input
                id="parcelas"
                v-model="antecipacao.contratoSelecionado.numeroParcelas"
                readonly
                type="text"
                placeholder="Parcelas"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </tab-content>

    <!-- Antecipação  -->
    <tab-content title="Dados da Antecipação" :before-change="validationAntecipacao">
      <validation-observer ref="dadosAntecipacaoRules" tag="form">
        <b-form>
          <!-- Tipo de antecipação  -->
          <b-row class="tw-mb-4">
            <b-col md="4" class="tw-flex tw-items-center tw-gap-2">
              <input
                id="exonerado"
                v-model="opcaoTipoAntecipacao"
                :checked="antecipacao.segurado.exonerado == true"
                :disabled="true"
                type="radio"
                value="Exonerado"
                name="status"
              />
              <label for="exonerado" class="tw-mb-0 tw-text-[16px]">Exonerado</label>
            </b-col>
            <b-col md="4" class="tw-flex tw-items-center tw-gap-2">
              <input
                id="falecido"
                v-model="opcaoTipoAntecipacao"
                type="radio"
                :checked="antecipacao.tomador.falecido"
                :disabled="true"
                value="Falecido"
                name="status"
              />
              <label for="falecido" class="tw-mb-0 tw-text-[16px]">Falecido</label>
            </b-col>
            <b-col md="4" class="tw-flex tw-items-center tw-gap-2">
              <input
                id="normal"
                v-model="opcaoTipoAntecipacao"
                :checked="!antecipacao.tomador.falecido && !antecipacao.segurado.exonerado"
                :disabled="true"
                type="radio"
                value="Normal"
                name="status"
              />
              <label for="normal" class="tw-mb-0 tw-text-[16px]">Normal</label>
            </b-col>
          </b-row>
          <!-- Tipo da antecipação campos -->
          <b-skeleton v-if="!antecipacao" type="input" />
          <TiposAntecipacao
            v-else
            :opcao-tipo-antecipacao="opcaoTipoAntecipacao"
            :antecipacao="antecipacao"
            @updateTableProps="updateTableProps"
          />
        </b-form>
      </validation-observer>
    </tab-content>

    <!-- Resumo  -->
    <tab-content title="Resumo" :before-change="validationResumo">
      <validation-observer ref="resumoRules" tag="form">
        <b-form>
          <b-row>
            <b-table
              striped
              responsive
              foot-clone
              class="position-relative"
              :per-page="perPageParcelas"
              :current-page="currentPageParcelas"
              :items="itemsParcelas"
              :fields="fieldsParcelas"
              :sort-by.sync="sortByParcelas"
              :sort-desc.sync="sortDescParcelas"
              :sort-direction="sortDirectionParcelas"
              :busy="isBusy"
            >
              <template #cell(valorAntecipacao)="data">
                <!-- Verifica se é a última linha -->
                <span v-if="isLastRow(data) == true && valorProRata != 0"
                  >{{ formatarValor(Number(data.item.valorAntecipado)) }}
                  <b-badge pill class="tw-text=xs">Pro rata: {{ formatarValor(valorProRata) }}</b-badge>
                </span>
                <span v-else>{{ formatarValor(Number(data.item.valorAntecipado)) }} </span>
              </template>

              <template #cell(valorPago)="row">
                {{
                  formatarParaNumber(row.item.valor) - formatarParaNumber(row.item.valorPago) != 0
                    ? formatarValor(row.item.valorPago) + ' (Parcial)'
                    : formatarValor(row.item.valorPago)
                }}
              </template>

              <template #foot(numeroParcela)>
                <span>Total:</span>
              </template>

              <template #foot(valorAntecipacao)>
                <span>{{ valorTotalParcelas ? valorTotalParcelas : formatarValor(0) }}</span>
              </template>

              <template #foot(valorParcela)>
                {{ '' }}
              </template>

              <template #foot(valorPago)>
                {{ '' }}
              </template>

              <template #foot(valor)>
                {{ '' }}
              </template>

              <template #foot(vencimento)>
                {{ '' }}
              </template>
            </b-table>

            <b-card-body class="d-flex justify-content-center flex-wrap pt-0">
              <b-pagination
                first-number
                last-number
                v-model="currentPageParcelas"
                :total-rows="totalRowsParcelas"
                :per-page="perPageParcelas"
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-card-body>
          </b-row>
        </b-form>
      </validation-observer>
    </tab-content>
  </form-wizard>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { parcela, required } from '@validations'
  import useJwt from '@/auth/jwt/useJwt'
  import Ripple from 'vue-ripple-directive'
  import { VueAutosuggest } from 'vue-autosuggest'
  import { isNumber, formatarValor, formatarData, formatarParaNumber, detectarNavegador } from '@/libs/utils'
  import Cleave from 'vue-cleave-component'
  import { FormWizard, TabContent } from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import TiposAntecipacao from './components/tiposAntecipacao.vue'
  import * as mathjs from 'mathjs'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      VueAutosuggest,
      Cleave,
      FormWizard,
      TabContent,
      formatarData,
      TiposAntecipacao,
      formatarParaNumber,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {
        origem: {
          type: String,
        },
      },
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        cadastroFinalizado: false,
        isBusy: false,
        valorProRata: 0,
        loading: false,
        isNumber,
        formatarValor,
        formatarParaNumber,
        formatarData,
        number: {},
        segurado: { tomador: {} },
        antecipacao: {
          segurado: {
            remuneracao: null,
          },
          tomador: {
            cpf: null,
            nomeCompleto: null,
          },
          contratoSelecionado: {},
          seguradoSelecionado: {},
          quantidadeDeParcelas: null,
          limiteParcelasAntecipar: 0,
          valorRenegociacao: 0,
        },
        suggestions: [],

        valorTotalParcelas: 0,
        timeout: null,
        debounceMilliseconds: 300,
        required,
        valor: 0,
        sortByParcelas: '',
        sortDescParcelas: false,
        sortDirectionParcelas: 'asc',
        perPageParcelas: 12,
        currentPageParcelas: 1,
        fieldsParcelas: [
          { key: 'numeroParcela', label: 'Número Parcela' },
          {
            key: 'valorAntecipacao',
            label: 'Valor Antecipação',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'valorParcela',
            label: 'Valor Anterior',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'vencimento',
            label: 'Vencimento',
            formatter: (value) => (value ? formatarData(value) : formatarData(0)),
          },
        ],
        totalRowsParcelas: 0,
        itemsParcelas: [],
        exibeDadosSegurado: false,
        cleaveCurrency: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          delimiter: '.',
          numeralDecimalMark: ',',
          numeralDecimalScale: 2,
        },
        options: {
          cleaveCPF: {
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
          },
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
        },
        statusTipoCalculo: {
          ambas: [
            { text: 'PRICE', value: 'Price' },
            { text: 'SAC', value: 'SAC' },
          ],
          price: [{ text: 'PRICE', value: 'Price' }],
          sac: [{ text: 'SAC', value: 'SAC' }],
        },
        optionsTipoCalculo: [
          { text: 'PRICE', value: 'Price' },
          { text: 'SAC', value: 'SAC' },
        ],
        optionsFormaCalculo: [
          { text: 'Valor do Empréstimo', value: 'valorDoEmprestimo' },
          { text: 'Valor da Parcela', value: 'valorDaParcela' },
        ],
        dados: [],
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPage: 1,
          statusOpcoes: '',
        },
        tipoOpcoes: [
          { value: 1, text: 'Nome' },
          { value: 2, text: 'CPF' },
          { value: 3, text: 'Nº Contrato' },
        ],
        statusOpcoes: [
          { value: '', text: 'Todos' },
          { value: 'GerouProposta', text: 'Gerou Proposta' },
          { value: 'Pendente', text: 'Pendente' },
          { value: 'Expirado', text: 'Expirado' },
        ],
        options: {
          cleaveCPF: {
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
          },
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
        },
        campoSeguradoSelecionado: true,
        opcaoTipoAntecipacao: null,
      }
    },
    mounted() {
      this.antecipacao = {
        ...this.antecipacao,
        institutoSelecionado: this.userData.institutoSelecionado,
        segurado: {
          ...this.antecipacao.segurado,
          situacaoPrevidenciaria: this.antecipacao.segurado.situacaoPrevidenciaria,
        },
      }

      this.exibeDadosSegurado = false
    },
    methods: {
      isLastRow(data) {
        if (data.item.parcelaProRata) {
          return true
        }
      },
      // Susgestões
      fetchResults() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.antecipacao.tomador.nomeCompleto.length >= 3) {
            useJwt
              .post('renegociacao/GetContratosParaAntecipacao', {
                descricao: this.antecipacao.tomador.nomeCompleto,
                institutoId: this.antecipacao.institutoSelecionado,
              })
              .then((response) => {
                this.suggestions = []
                if (response.data.length > 0) {
                  this.suggestions = [{ data: response.data }]
                } else {
                  this.$message.error('Nenhum registro encontrado com o nome informado.')
                }
              })
              .catch((error) => {
                console.error(error)
              })
          } else this.suggestions = []
        }, this.debounceMilliseconds)
      },
      // Antecipacao resultado
      getSuggestionValue(suggestion) {
        const { item } = suggestion

        this.campoSeguradoSelecionado = false
        this.exibeDadosSegurado = true
        this.antecipacao.id = item.id
        this.antecipacao.tomador = item.segurado.tomador
        this.antecipacao.segurado = item.segurado
        this.antecipacao.segurado.remuneracao = formatarValor(item.segurado.remuneracao)
        this.antecipacao.tomador.dataNascimento = formatarData(item.segurado.tomador.dataNascimento)
        this.antecipacao.seguradoSelecionado = item.segurado
        this.antecipacao.contratoSelecionado = item
        this.antecipacao.contratoSelecionado.valorContrato = formatarValor(item.valorContrato)
        this.antecipacao.contratoSelecionado.valorParcela = formatarValor(item.valorParcela)

        this.opcaoTipoAntecipacao = this.antecipacao.segurado.exonerado
          ? 'Exonerado'
          : this.antecipacao.tomador.falecido
          ? 'Falecido'
          : 'Normal'

        const parametro = {
          institutoId: this.userData.institutoSelecionado,
          contratoId: this.antecipacao.contratoSelecionado.id,
          tipo: this.opcaoTipoAntecipacao,
        }

        if (this.opcaoTipoAntecipacao == 'Exonerado') {
          useJwt.post('renegociacao/getQuantidadeParcelasAAntecipar', parametro).then((response) => {
            this.antecipacao.limiteParcelasAntecipar = response.data
          })
        }

        if (this.opcaoTipoAntecipacao == 'Falecido') {
          useJwt.post('renegociacao/getQuantidadeParcelasAAntecipar', parametro).then((response) => {
            this.antecipacao.limiteParcelasAntecipar = response.data
          })
        }

        if (this.opcaoTipoAntecipacao == 'Normal') {
          useJwt.post('renegociacao/getQuantidadeParcelasAAntecipar', parametro).then((response) => {
            this.antecipacao.limiteParcelasAntecipar = response.data
          })
        }

        return item.nomeCompleto
      },
      validationDadosSegurado() {
        return new Promise(async (resolve, reject) => {
          await this.$refs.tomRules.validate().then(async (success) => {
            if (success && this.antecipacao?.tomador?.nomeCompleto) {
              if (!this.suggestions.length || !this.antecipacao.tomador.nomeCompleto) {
                this.$message.error('Alerta! Selecione um segurado da lista de sugestões para avançar.')
                reject()
              } else {
                resolve(true)
              }
            } else {
              let title
              if (!success) {
                title = 'Alerta! Há campos não preenchidos no formulário.'
              } else {
                title = 'Alerta! Selecione o segurado na pesquisa para avançar.'
              }
              this.$message.error(title)
              reject()
            }
          })
        })
      },
      validationAntecipacao() {
        if (!this.antecipacao.valorRenegociacao && this.opcaoTipoAntecipacao == 'Exonerado') {
          this.$message.error('Alerta! Verifique e preencha o formulário corretamente')
          return
        }
        return new Promise(async (resolve, reject) => {
          await this.$refs.dadosAntecipacaoRules.validate().then(async (success) => {
            if (success) {
              if (this.opcaoTipoAntecipacao != 'Exonerado') this.buscaParcelasAAntecipar()
              resolve(true)
            } else {
              let title
              if (!success) {
                title = 'Alerta! Verifique e preencha o formulário corretamente.'
              } else {
                title = 'Alerta! Selecione o segurado na pesquisa para avançar.'
              }
              this.$message.error(title)
              reject()
            }
          })
        })
      },
      validationResumo() {
        return new Promise(async (resolve, reject) => {
          await this.$refs.resumoRules.validate().then(async (success) => {
            if (success) {
              resolve(true)
            } else {
              let title
              if (!success) {
                title = 'Alerta! Há campos não preenchidos no formulário.'
              } else {
                title = 'Alerta! Selecione o segurado na pesquisa para avançar.'
              }
              this.$message.error(title)
              reject()
            }
          })
        })
      },
      async buscaParcelasAAntecipar() {
        this.isBusy = true
        const parametro = {
          institutoId: this.userData.institutoSelecionado,
          contratoId: this.antecipacao.contratoSelecionado.id,
          quantidadeDeParcelas: this.antecipacao.quantidadeDeParcelas,
        }
        useJwt.post('renegociacao/GetCalcularParcelasAAntecipar', parametro).then((response) => {
          if (response.data?.status == 'erro') {
            this.isBusy = false
            console.error(response.data.mensagem)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Alerta!',
                  text: 'Contrato já possui uma antecipação!',
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'top-right',
                timeout: 8000,
              },
            )
            this.$emit('closeCadastroModal')
            return
          }
          const proRata = response?.data?.proRata

          this.valorProRata = Number(proRata).toFixed(2)
          this.itemsParcelas = response.data.parcelasRetorno
          this.totalRowsParcelas = response.data.parcelasRetorno.length
          this.valorTotalParcelas = formatarValor(
            response.data.parcelasRetorno.reduce((acc, item) => acc + Number(item.valorAntecipado), 0),
          )
          this.isBusy = false
        })
      },
      async salvarAntecipacao() {
        const navegadorUtilizado = detectarNavegador()

        if (this.opcaoTipoAntecipacao == 'Exonerado') {
          this.valorTotalParcelas = 0
          for (const parcela of this.itemsParcelas) {
            parcela.valorAntecipado = parcela.valorPago
            this.valorTotalParcelas = Number(
              mathjs.add(mathjs.bignumber(formatarParaNumber(this.valorTotalParcelas)), mathjs.bignumber(parcela.valorPago)),
            )
          }
        }

        const dataToSave = {
          valor: formatarParaNumber(this.valorTotalParcelas),
          valorContrato: formatarParaNumber(this.antecipacao.contratoSelecionado.valorContrato),
          seguradoId: this.antecipacao.seguradoSelecionado.id,
          contratoId: this.antecipacao.contratoSelecionado.id,
          parcelas: this.itemsParcelas,
          jurosProRata: this.valorProRata,
          institutoId: this.userData.institutoSelecionado,
          navegadorUtilizado,
        }

        this.isBusy = true
        useJwt
          .post('renegociacao/CadastraAntecipacao', dataToSave)
          .then((response) => {
            if (response.data.status === 'erro') {
              console.error(response.data.mensagem)
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Alerta!',
                    text: 'Houve um erro ao salvar a antecipação!',
                    icon: 'BellIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-right',
                  timeout: 8000,
                },
              )

              this.isBusy = false
              this.$emit('atualizarGrid')
              this.$emit('closeCadastroModal')
              return
            }

            this.$swal({
              title: 'Sucesso!',
              text: 'Antecipação criada com sucesso!',
              icon: 'success',
              confirmButtonText: 'OK',
            })
            this.$message.success('Antecipação criada com sucesso!')
            this.$emit('atualizarGrid')
            this.$emit('closeCadastroModal')
          })
          .catch((error) => {
            this.isBusy = false
            console.error(error)
            this.$message.error('Erro ao salvar a antecipação!')
          })
      },
      updateTableProps(props) {
        this.fieldsParcelas = props.fieldsParcelas
        this.itemsParcelas = props.itemsParcelas
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-autosuggest.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
