<template>
  <b-card-code no-body @keydown.enter="carregarGrid">
    <b-card-body>
      <b-row>
        <b-col md="2">
          <b-form-group label="Pesquisar Por" label-for="opcao">
            <b-form-select id="opcao" v-model="pesquisar.tipoOpcoes" :options="tipoOpcoes" />
          </b-form-group>
        </b-col>
        <b-col v-if="this.pesquisar.tipoOpcoes != 2" md="3">
          <b-form-group label="Descrição" label-for="descricao">
            <b-form-input id="descricao" v-model="pesquisar.descricao" placeholder="Descrição" />
          </b-form-group>
        </b-col>
        <b-col v-if="this.pesquisar.tipoOpcoes == 2" md="3">
          <b-form-group label="Descrição" label-for="descricao">
            <cleave
              id="descricao"
              v-model="pesquisar.descricao"
              placeholder="Descrição"
              class="form-control"
              :options="options.cleaveCPF"
              @keypress="this.isNumber()"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Data Inicial" label-for="dataInicial">
            <validation-provider #default="{ errors }" name="Data Inicial">
              <b-input-group>
                <cleave
                  id="dataInicial"
                  type="text"
                  v-model="pesquisar.dataInicial"
                  placeholder="DD/MM/AAAA"
                  class="form-control cleave-height"
                  :options="options.cleaveDate"
                  :raw="false"
                />
                <b-input-group-append>
                  <b-form-datepicker
                    size="sm"
                    button-variant="primary"
                    class="datePickerWithInput"
                    right
                    button-only
                    v-model="dataInicialDatePicker"
                    :hide-header="true"
                    :initial-date="new Date()"
                    :state="errors[0] ? false : null"
                    label-help=""
                    label-calendar="Calendário"
                    label-close-button="Fechar"
                    label-current-month="Mês atual"
                    label-next-decade="Próxima década"
                    label-next-month="Próximo mês"
                    label-next-year="Próximo ano"
                    label-prev-decade="Década anterior"
                    label-prev-month="Mês anterior"
                    label-prev-year="Ano anterior"
                    label-select-month="Selecione o mês"
                    label-select-year="Selecione o ano"
                    label-selected="Selecionado"
                    label-no-date-selected="Nenhuma data selecionada"
                    label-reset-button="Resetar"
                    label-today-button="Hoje"
                    label-today="Hoje"
                    locale="pt-br"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
                    aria-controls="dataInicial"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Data Final" label-for="dataFinal">
            <validation-provider
              #default="{ errors }"
              name="Data Inicial"
              :rules="{
                dataPassada: {
                  value: pesquisar.dataFinal,
                  dataParametro: pesquisar.dataInicial,
                  stringParametro: 'data inicial',
                },
              }"
            >
              <b-input-group>
                <cleave
                  id="dataFinal"
                  type="text"
                  v-model="pesquisar.dataFinal"
                  placeholder="DD/MM/AAAA"
                  class="form-control cleave-height"
                  :options="options.cleaveDate"
                  :raw="false"
                />
                <b-input-group-append>
                  <b-form-datepicker
                    size="sm"
                    button-variant="primary"
                    class="datePickerWithInput"
                    right
                    button-only
                    v-model="dataFinalDatePicker"
                    :hide-header="true"
                    :initial-date="new Date()"
                    :state="errors[0] ? false : null"
                    label-help=""
                    label-calendar="Calendário"
                    label-close-button="Fechar"
                    label-current-month="Mês atual"
                    label-next-decade="Próxima década"
                    label-next-month="Próximo mês"
                    label-next-year="Próximo ano"
                    label-prev-decade="Década anterior"
                    label-prev-month="Mês anterior"
                    label-prev-year="Ano anterior"
                    label-select-month="Selecione o mês"
                    label-select-year="Selecione o ano"
                    label-selected="Selecionado"
                    label-no-date-selected="Nenhuma data selecionada"
                    label-reset-button="Resetar"
                    label-today-button="Hoje"
                    label-today="Hoje"
                    locale="pt-br"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
                    aria-controls="dataFinal"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Sexo" label-for="Sexo">
            <b-form-select id="Sexo" v-model="pesquisar.tipoOpcoesSexo" :options="tipoOpcoesSexo" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2">
          <b-form-group label="Valor Inicial" label-for="Valor Inicial">
            <b-form-input id="valorInicial" v-model="pesquisar.valorInicial" placeholder="Valor Inicial" />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Valor Final" label-for="Valor Final">
            <b-form-input id="valorFinal" v-model="pesquisar.valorFinal" placeholder="Valor Final" />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Órgão" label-for="Órgão">
            <b-form-select
              id="tipoOrgaos"
              v-model="pesquisar.tipoOrgaos"
              :options="this.tipoOrgaos"
              value-field="id"
              text-field="nome"
            />
          </b-form-group>
        </b-col>
        <b-col md="auto">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :disabled="isBusy"
            variant="primary"
            style="margin-top: 21px; width: 160px"
            @click="carregarGrid()"
          >
            {{ !isBusy ? 'Pesquisar' : '' }}
            <b-spinner v-if="isBusy" small />
          </b-button>
        </b-col>
        <b-col md="auto">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            style="margin-top: 21px; width: 160px"
            @click="novaAntecipacao()"
          >
            Novo Cadastro
          </b-button>
        </b-col>
        <b-col md="auto">
          <template>
            <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
              <template #button-content>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" style="margin-top: 20px; width: 160px">
                  Exportar
                </b-button>
              </template>
              <b-dropdown-item href="#" @click="exportToPDF()">
                <feather-icon icon="FileTextIcon" size="16" />
                <span class="align-middle ml-50" title="Exportar para PDF">PDF</span>
              </b-dropdown-item>
              <b-dropdown-item href="#" @click="exportToExcel()">
                <feather-icon icon="CheckCircleIcon" size="16" />
                <span class="align-middle ml-50" title="Exportar para excel">Excel</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-col>
      </b-row>
    </b-card-body>

    <b-col v-show="!acesso.CadastroTomadorExcluir && !acesso.CadastroTomadorEditar" md="12" align="left">
      <b-card-text class="mb-1 text-danger">
        A coluna "OPÇÕES" está desativada. Seu usuário não tem essa permissão
      </b-card-text>
    </b-col>

    <b-table
      v-show="items.length > 0"
      id="tableAntecipacaoParcelas"
      striped
      responsive
      class="position-relative"
      :per-page="pesquisar.perPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :busy="isBusy"
      :current-page="pesquisar.currentPageTomador"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1" />
          <strong> Carregando...</strong>
        </div>
      </template>
      <template v-if="acesso.CadastroTomadorExcluir && acesso.CadastroTomadorEditar" #cell(opcoes)="row">
        <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              title="Este botão contém as opções disponíveis para este registro"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>
          <b-dropdown-item v-show="acesso.CadastroTomadorEditar" @click="visualizarAntecipacao(row.item)">
            <feather-icon icon="EditIcon" size="16" />
            <span class="align-middle ml-50">Visualizar Antecipação</span>
          </b-dropdown-item>
          <b-dropdown-item
            id="PagarAntecipacao"
            v-show="(row.item.tipoBaixaAntecipacao || row.item.falecido) && row.item.statusPagamento !== 'Pago'"
            @click="confirmarPagamento(row.item.id)"
          >
            <feather-icon icon="CheckIcon" size="16" />
            <span class="align-middle ml-50">Confirmar Pagamento</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="row.item.tipoBaixaAntecipacao && row.item.statusPagamento !== 'Pago' && !row.item.falecido"
            @click="baixarDocumentoDeBaixa(row.item)"
          >
            <feather-icon icon="FileTextIcon" size="16" />
            <span v-if="row.item.tipoBaixaAntecipacao == 'boleto'" class="align-middle ml-50">Baixa Boleto</span>
            <span v-if="row.item.tipoBaixaAntecipacao == 'guia'" class="align-middle ml-50">Baixar Guia</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <template #cell(categorias)="row">
        <span v-for="item in row.item.categorias.split(',')" :key="item">
          <b-badge pill variant="primary">
            {{ item.trim() }}
          </b-badge>
        </span>
      </template>
    </b-table>

    <div>
      <relatorioAntecipacaoParcelas ref="tableToExport" :parametro="parametroPDF" id="tableToExport" />
    </div>

    <b-modal
      id="modal-vencimento-boleto"
      ref="modal-vencimento-boleto"
      centered
      title="Data de vencimento"
      hide-footer
      no-close-on-backdrop
      ok-only
    >
      <validation-observer ref="boletoRules" tag="form">
        <validation-provider #default="{ errors }" name="Data de Vencimento" rules="required">
          <b-form-datepicker
            button-variant="primary"
            class="datePickerWithInput"
            v-model="dataVencimento"
            :min="new Date()"
            :hide-header="true"
            :initial-date="new Date()"
            label-help=""
            label-calendar="Calendário"
            label-close-button="Fechar"
            label-current-month="Mês atual"
            label-next-decade="Próxima década"
            label-next-month="Próximo mês"
            label-next-year="Próximo ano"
            label-prev-decade="Década anterior"
            label-prev-month="Mês anterior"
            label-prev-year="Ano anterior"
            label-select-month="Selecione o mês"
            label-select-year="Selecione o ano"
            label-selected="Selecionado"
            label-no-date-selected="Nenhuma data selecionada"
            label-reset-button="Resetar"
            label-today-button="Hoje"
            label-today="Hoje"
            locale="pt"
            :date-format-options="{ day: 'numeric', month: '2-digit', year: 'numeric' }"
            aria-controls="dataVencimento"
          />
          <small class="text-danger">{{ errors[0] }}</small>
          <b-button variant="primary" @click="gerarBoleto(itemBoleto)" class="mt-1" block>Gerar</b-button>
        </validation-provider>
      </validation-observer>
    </b-modal>

    <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        style="align-items: center"
        label="Por Página"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        first-number
        last-number
        v-model="pesquisar.currentPageTomador"
        :total-rows="totalRows"
        :per-page="pesquisar.perPage"
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>

    <!-- Modal Cadastro -->
    <b-modal
      id="modal-antecipacao"
      ref="modal-antecipacao"
      centered
      size="lg"
      title="Nova Antecipação"
      no-close-on-backdrop
      hide-footer
      no-close-on-esc
    >
      <validation-observer ref="formProposta">
        <antecipacaoParcelasCadastro
          ref="propostaRef"
          @atualizarGrid="carregarGrid"
          @closeCadastroModal="fecharModalCadastro"
        />
      </validation-observer>
    </b-modal>

    <!-- Modal Visualizar -->
    <b-modal
      id="modal-visualizar"
      ref="modal-visualizar"
      centered
      size="xl"
      title="Detalhes dessa Renegociação"
      hide-footer
      no-close-on-backdrop
      ok-only
    >
      <antecipacao-parcelas-visualizar
        ref="antecipacaoRef"
        :parametroDadosAntecipacao="dadosAntecipacao"
        :parametro="parametroVisualizar"
        @carregar-grid="carregarGrid"
      ></antecipacao-parcelas-visualizar>
    </b-modal>
  </b-card-code>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import Cleave from 'vue-cleave-component'
  import {
    PascalCaseComEspaco,
    formatarCPF,
    formatarData,
    formatarDataGlobal,
    formatarValor,
    isLetter,
    isNumber,
  } from '@/libs/utils'
  import antecipacaoParcelasCadastro from '@/views/pages/renegociacao/antecipacaoParcelas/antecipacaoParcelasCadastro.vue'
  import AntecipacaoParcelasVisualizar from './antecipacaoParcelasVisualizar.vue'
  import { geraBoleto, consultaBoletoGerado, adicionaBoletoNaGeracaoDeRemessa } from '@/utils/boleto/index'
  import DatePicker from '@/views/components/DatePicker.vue'
  import VueHtml2pdf from 'vue-html2pdf'
  import relatorioAntecipacaoParcelas from './components/relatorioAntecipacaoParcelas.vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { generateAndDownloadPdf } from '@/utils/geradorPDF/geradorPDF.js'
  import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
  import { jwtDecode } from 'jwt-decode'

  export default {
    components: {
      generateAndDownloadPdf,
      ToastificationContent,
      DatePicker,
      antecipacaoParcelasCadastro,
      ValidationProvider,
      ValidationObserver,
      Cleave,
      formatarData,
      BCardCode,
      AntecipacaoParcelasVisualizar,
      VueHtml2pdf,
      relatorioAntecipacaoParcelas,
      FeatherIcon,
      ToastificationContent,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        nomeRelatorio: 'Relatório de Antecipação',
        pdfReady: false,
        itemBoleto: undefined,
        required,
        isLetter,
        isNumber,
        parametroVisualizar: {},
        dadosAntecipacao: {},
        userData: jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: false,
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPageTomador: 1,
          tipoOpcoesSexo: -1,
          tipoOrgaos: -1,
        },
        loading: false,
        parametroPDF: {
          dados: [],
          colunas: [],
        },
        tipoOpcoes: [
          { value: 1, text: 'Nome' },
          { value: 2, text: 'CPF' },
          { value: 3, text: 'Matricula' },
          { value: 4, text: 'Nº Contrato' },
          { value: 5, text: 'Categoria' },
        ],
        tipoOpcoesSexo: [
          { value: -1, text: 'Todos' },
          { value: 'Masculino', text: 'Masculino' },
          { value: 'Feminino', text: 'Feminino' },
        ],
        tipoOrgaos: [],
        options: {
          cleaveCPF: {
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
            numericOnly: true,
          },
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
          clevePhone: { phone: true, phoneRegionCode: 'BR', delimiter: ' ' },
          cleaveCEP: {
            delimiters: ['.', '.', '-'],
            blocks: [2, 3, 3],
          },
        },
        categorias: [],
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'desc',
        fieldsPDF: [
          {
            key: 'matricula',
            label: 'Matricula',
          },
          {
            key: 'nomeCompleto',
            label: 'Nome Completo',

            formatter: (value) => (value ? PascalCaseComEspaco(value) : ''),
          },
          {
            key: 'CPF',
            label: 'CPF',
          },
          {
            key: 'codigoContrato',
            label: 'Nº Contrato',
          },
          {
            key: 'valorAntecipacao',
            label: 'Valor da Antecipação',

            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'valorAnterior',
            label: 'Valor Anterior',

            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'dataAntecipacao',
            label: 'Data Antecipação',
          },
          {
            key: 'parcelas',
            label: 'Parcelas',
          },
          {
            key: 'categorias',
            label: 'Categorias',
          },
          {
            key: 'telefone',
            label: 'Telefone',
          },
        ],
        fields: [
          {
            key: 'opcoes',
            label: 'OPÇÕES',
          },
          {
            key: 'matricula',
            label: 'Matricula',
            sortable: true,
          },
          {
            key: 'nomeCompleto',
            label: 'Nome Completo',
            sortable: true,
            formatter: (value) => (value ? PascalCaseComEspaco(value) : ''),
          },
          {
            key: 'CPF',
            label: 'CPF',
            sortable: true,
          },
          {
            key: 'codigoContrato',
            label: 'Nº Contrato',
            sortable: true,
          },
          {
            key: 'valorAntecipacao',
            label: 'Valor da Antecipação',
            sortable: true,
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'valorAnterior',
            label: 'Valor Anterior',
            sortable: true,
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'dataAntecipacao',
            label: 'Data Antecipação',
            sortable: true,
          },
          {
            key: 'parcelas',
            label: 'Parcelas',
          },
          {
            key: 'categorias',
            label: 'Categorias',
            sortable: true,
          },
          {
            key: 'telefone',
            label: 'Telefone',
            sortable: true,
          },
        ],
        items: [],
        acesso: {
          CadastroTomadorConsulta: false,
          CadastroTomadorCriarNovo: false,
          CadastroTomadorExcluir: false,
          CadastroTomadorEditar: false,
        },
        dados: {},
        tomadorWithOrg: {},
        log: [],
        categoria: [
          {
            Ativo: 'primary',
            Aposentado: 'light-success',
            Pensionista: 'light-danger',
          },
        ],
        dataVencimento: null,
        dataInicialDatePicker: '',
        dataFinalDatePicker: '',
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
    },
    mounted() {
      this.pdfReady = true
      if (this.userData.role === 'master') {
        this.acesso.CadastroTomadorConsulta = true
        this.acesso.CadastroTomadorCriarNovo = true
        this.acesso.CadastroTomadorEditar = true
        this.acesso.CadastroTomadorExcluir = true
      } else {
        this.acesso.CadastroTomadorConsulta = this.userData.GrupoAcesso.CadastroTomadorConsulta
        this.acesso.CadastroTomadorCriarNovo = this.userData.GrupoAcesso.CadastroTomadorCriarNovo
        this.acesso.CadastroTomadorEditar = this.userData.GrupoAcesso.CadastroTomadorEditar
        this.acesso.CadastroTomadorExcluir = this.userData.GrupoAcesso.CadastroTomadorExcluir
      }
      this.carregarOrgaos()
    },
    methods: {
      carregarOrgaos() {
        if (this.userData.institutoSelecionado) {
          useJwt
            .pesquisar('cadastro/orgao/GetPesquisar', {
              ...this.pesquisar,
              institutoSelecionado: this.userData.institutoSelecionado,
            })
            .then((response) => {
              this.tipoOrgaos = response.data

              this.tipoOrgaos.map((orgao) => ({
                value: orgao.id,
                text: orgao.nome,
              }))
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      novaAntecipacao() {
        this.$refs['modal-antecipacao'].show()
      },

      carregarGrid() {
        this.isBusy = true
        let parametros = {
          ...this.pesquisar,
          institutoId: this.userData.institutoSelecionado,
        }

        if (this.pesquisar.tipoOpcoes == 2) {
          parametros.descricao = formatarCPF(this.pesquisar.descricao)
        }

        useJwt
          .post('renegociacao/GetPesquisarAntecipacoes', parametros)
          .then((response) => {
            if (response.data.length > 0) {
              this.isBusy = false
              this.items = response.data
              this.totalRows = response.data.length
            } else {
              this.isBusy = false
              this.items = []
              this.$message.error('Não foi encontrado nenhuma antecipação')
            }

            this.itemsTabela = this.items.map((item) => ({
              ...item,
              matricula: item.matricula,
              nomeCompleto: item.nomeCompleto,
              CPF: item.CPF,
              codigoContrato: item.codigoContrato,
              valorAntecipacao: item.valorAntecipacao,
              valorAnterior: item.valorAnterior,
              dataAntecipacao: item.dataAntecipacao,
              parcelas: item.parcelas,
              categorias: item.categorias,
              telefone: item.telefone,
              tipoBaixaAntecipacao: item.tipoBaixaAntecipacao,
              falecido: item.falecido,
            }))
            this.totalRows = this.itemsTabela.length
            this.items = response.data
            this.linhasTabela = this.itemsTabela
            this.colunasTabelas = this.fieldsPDF.map((item) => ({
              label: item.label,
              key: item.key,
            }))

            const colunasPDF = [
              { key: 'matricula', label: 'Matricula' },
              { key: 'nomeCompleto', label: 'Nome Completo', formatter: (value) => (value ? PascalCaseComEspaco(value) : '') },
              { key: 'CPF', label: 'CPF' },
              { key: 'codigoContrato', label: 'Nº Contrato' },
              {
                key: 'valorAntecipacao',
                label: 'Valor da Antecipação',
                formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
              },
              {
                key: 'valorAnterior',
                label: 'Valor Anterior',
                formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
              },
              { key: 'dataAntecipacao', label: 'Data Antecipação' },
              { key: 'parcelas', label: 'Parcelas' },
              { key: 'categorias', label: 'Categorias' },
              { key: 'telefone', label: 'Telefone' },
            ]
            const itemsPDF = this.linhasTabela.map((item) => {
              return {
                ...item,
              }
            })
            this.parametroPDF = {
              dados: itemsPDF,
              colunas: colunasPDF.filter((item) => item.key),
            }
          })
          .catch((error) => {
            this.isBusy = false
            console.error(error.response.data.message)
            this.$message.error('Erro ao consultar as antecipações')
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      fecharModalCadastro() {
        this.$refs['modal-antecipacao'].hide()
      },
      visualizarAntecipacao(dados) {
        this.parametroVisualizar = { id: dados.id }
        this.dadosAntecipacao = dados
        this.$refs['modal-visualizar'].show()
      },
      async gerarBoleto(item) {
        this.$refs.boletoRules.validate().then(async (success) => {
          if (success) {
            this.$refs['modal-vencimento-boleto'].hide()
            this.$message.info('Iniciando a geração do boleto.')

            const institutoId = item.institutoId
            const tomadorId = item.tomadorId
            const renegociacaoId = item.id
            const valorBoleto = item.valorAntecipacao

            let rt = await adicionaBoletoNaGeracaoDeRemessa({
              institutoId: institutoId,
              renegociacaoAntecipacaoId: renegociacaoId,
              tomadorId: tomadorId,
              geradoPor: 'porAntecipacao',
              dataVencimento: this.dataVencimento,
            })

            if (rt.status != 200) {
              rt = await consultaBoletoGerado({
                institutoId: institutoId,
                renegociacaoAntecipacaoId: renegociacaoId,
                tomadorId: tomadorId,
                geradoPor: 'porAntecipacao',
                dataVencimento: this.dataVencimento,
              })
            }

            const obj = await geraBoleto(institutoId, tomadorId, {
              valor: valorBoleto,
              dataVencimento: this.dataVencimento,
              nossoNumero: `${rt.data.id}`,
              numeroDocumento: `${rt.data.id}`,
            })

            const resp = await useJwt.post('boleto/geraBoleto', obj)

            if (resp.data.status != 200) {
              this.$message.error('Erro ao gerar boleto')
              this.dataVencimento = null
              return
            }

            this.$message.success('Boleto gerado com sucesso')
            this.dataVencimento = null
            window.open(resp.data.response, resp.data.response)
          }
        })
      },

      exportToPDF() {
        if (!this.itemsTabela.length > 0) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Alerta!',
                text: 'Não há dados para exportar!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
          return
        }
        if (this.$refs.tableToExport) {
          let htmlContent = this.$refs.tableToExport.$children[0].$refs.html2PdfLandscape.$el.innerHTML
          let relatorioPaisagem = true
          let nomeInstituto = this.userData.Instituto.nome
          let periodoFiltro = `Período: ${this.dataInicial} a ${this.dataFinal}`
          let nomeRelatorio = this.nomeRelatorio
          let informacoesInstituto = `
                      ${this.userData.Instituto.logradouro},
                      ${this.userData.Instituto.numero},
                      ${this.userData.Instituto.bairro},
                      ${this.userData.Instituto.cep} - ${this.userData.Instituto.cidade} - ${this.userData.Instituto.uf}
                      E-mail: ${this.userData.Instituto.emailResponsavel} - Fone:${this.userData.Instituto.telefone}
                    `
          generateAndDownloadPdf(
            this,
            htmlContent,
            nomeInstituto,
            periodoFiltro,
            nomeRelatorio,
            informacoesInstituto,
            relatorioPaisagem,
            this.userData.Instituto.id,
          )
        } else {
          console.error('Elemento da tabela não encontrado')
        }
      },

      exportToExcel() {
        var tab_text = "<table border='2px' style='border-collapse: collapse;'>"
        var j = 0
        let tab = document.getElementById('tableAntecipacaoParcelas')
        //tive que clonar a tabela pq estava removendo o elemento diretamente na grid
        let tabClone = tab.cloneNode(true)

        for (var i = 0; i < tabClone.rows.length; i++) {
          tabClone.rows[i].deleteCell(0)
        }

        for (var i = 0; i < tabClone.rows.length; i++) {
          for (var j = 0; j < tabClone.rows[i].cells.length; j++) {
            tabClone.rows[i].cells[j].style.border = '1px solid #dddddd' // Cor e estilo da borda
          }
        }

        let regex = /\s*\(Click to sort (Descending|Ascending)\)/g
        tabClone.innerHTML = tabClone.innerHTML.replace(regex, '')

        var tab_text = tabClone.outerHTML

        tab_text = tab_text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        tab_text = tab_text + '</table>'
        tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, '')
        tab_text = tab_text.replace(/<img[^>]*>/gi, '')
        tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, '')

        let fileName = 'Relatorio'
        let ua = window.navigator.userAgent
        let msie = ua.indexOf('MSIE ')
        let sa

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
          // If Internet Explorer
          txtArea1.document.open('txt/html', 'replace')
          txtArea1.document.write(tab_text)
          txtArea1.document.close()
          txtArea1.focus()
          sa = txtArea1.document.execCommand('SaveAs', true, fileName)
        } //other browser not tested on IE 11
        else {
          let blob = new Blob([tab_text], { type: 'application/vnd.ms-excel' })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = fileName
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }

        return sa
      },

      confirmarPagamento(id) {
        const data = {
          id: id,
          institutoId: this.userData.institutoSelecionado,
        }

        try {
          useJwt
            .put('/renegociacao/PagamentoAntecipacao', data)
            .then((response) => {
              this.$message.success('Antecipação paga com sucesso')
              this.carregarGrid()
            })
            .catch((error) => {
              console.error(error)
            })
        } catch (error) {
          console.error(error)
          this.$message.error('Erro ao pagar a antecipação')
        }
      },
      baixarDocumentoDeBaixa(antecipacao) {
        console.log(antecipacao)
        const parametro = {
          renegociacaoAntecipacaoId: antecipacao.id,
        }
        useJwt
          .post('aws/getDownloadArquivoAws', parametro)
          .then((response) => {
            const s3Key = response.data.s3Key
            return useJwt.post('aws/getDownladArquivoAwsCrypt', { s3Key })
          })
          .then((response) => {
            const data = response.data
            const buffer = new Uint8Array(data.Body.data) // Extrai o buffer dos dados
            const blob = new Blob([buffer], { type: data.ContentType })
            saveAs(blob, 'boleto.pdf')
            const url = window.URL.createObjectURL(blob)
            window.open(url, '_blank')
            setTimeout(() => window.URL.revokeObjectURL(url), 100)
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao baixar o documento de baixa')
          })
      },
    },
    watch: {
      dataInicialDatePicker() {
        this.pesquisar.dataInicial = formatarData(this.dataInicialDatePicker)
      },
      dataFinalDatePicker() {
        this.pesquisar.dataFinal = formatarData(this.dataFinalDatePicker)
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  div#modal-cadastro-tomador > div.modal-dialog.modal-lg.modal-dialog-centered {
    max-width: 1200px;
  }

  .margimBadge > span {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  #grupoCategoriasConfiguracaoCriteriosElegibilidade > div {
    display: flex;
  }

  .mt-18em {
    margin-top: 1.8em !important;
  }
  .titulo {
    background-color: inherit !important;
    justify-content: flex-start;
    word-break: break-all;
    padding-top: 5px;
  }
  .cabecalhoTable {
    margin-bottom: 0px;
    font-size: 12px;
    word-wrap: normal;
    white-space: pre-wrap;
  }
  .fundoRelatorio {
    background-color: white;
    word-break: break-word;
  }
  .instituto {
    font-size: initial;
    padding-left: 20px;
  }
  .tituloRelatorio {
    text-align: center;
    font-weight: bold;
    font-size: large;
    color: #615771;
  }
</style>
